window.onscroll = function () {
	let header = document.querySelector("header")
	if (header) {
		if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
			header.classList.add("scrolled")
		} else {
			header.classList.remove("scrolled")
		}
	}
}


